<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="8">
          <el-form-item label="起始结束时间">
            <el-date-picker
                style="height: 100%;"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import Searchbar from '@/components/Searchbar';
import { queryHistoryMeeting } from "@/api/yuwu/historyMeeting";
import { changeTime } from "../../util/date";
export default {
  components:{
    Searchbar
  },
  setup(){
    let state = reactive({
      time:[],
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "confId",
          label: "会议号",
          render: (row) => <div>{row.confId}</div>,
        },
        {
          prop: "subject",
          label: "主题",
          width: 300,
          render: (row) => {
            const op = new Map([
              [0, '非周期会议'],
              [1, '每天'],
              [2, '每个工作日'],
              [3, '每周'],
              [4, '每两周'],
              [5, '每月']
            ])
            return <div>{row.subject} <el-tag type="success">{op.get(row.periodType)}</el-tag></div>
          }
        },
        {
          prop: "startTime",
          label: "开始时间",
          render: (row) => <div>{changeTime(row.startTime)}</div>,
        },
        {
          prop: "endTime",
          label: "结束时间",
          render: (row) => <div>{changeTime(row.endTime)}</div>,
        },
        {
          prop: "hostName",
          label: "主持人",
          render: (row) => <div>{row.hostName}</div>,
        },
        {
          prop: "members",
          label: "参会人数",
          render: (row) => <div>{row.members}</div>,
        }
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })
    onMounted(()=>{
      onLoad();
    })
    function onLoad(form = {}){
      queryHistoryMeeting({ size :state.page.limit, current:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.records;
        state.page.total = res.data.data.total;
      })
    }

    function onSearch(){
      state.page.current = 1;
      if(state.time){
        state.searchForm.startTime = new Date(state.time[0]).getTime() / 1000;
        state.searchForm.endTime = new Date(state.time[1]).getTime() / 1000;
      }
      onLoad(state.searchForm)
    }

    function resetForm() {
      state.page.current = 1;
      state.time = [];
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    return{
      handleCurrentChange,
      handleSizeChange,
      resetForm,
      onSearch,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>